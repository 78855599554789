import React from "react";

import Album from "./Album.jsx";
import Podgalerija from "./Podgalerija.jsx";

let slike = {};
const sl = require.context('../public/slike/galerija', true, /\.(png|jpe?g|svg)$/);

sl.keys().forEach(function(key){

	let z = key.indexOf("male");

	if(z !== -1){
		let s = key.substring(2, z - 1);

		if(s.indexOf("/") !== -1){
			s = s.substring(s.indexOf("/") + 1);
		}

		if(s in slike){
			slike[s].push(key.substring(z + 5));
		}
		else{
			slike[s] = [key.substring(z + 5)];
		}
	}
});

class Galerija extends React.Component{
   	
	constructor(props){
		super(props);

		this.stran = this.stran.bind(this);

		this.state = {
			stran: 1000
		};
	}

	stran(i){
		this.setState({
			stran: i
		});

		window.history.pushState(i, "", "");
	}

   	render(){

	   	let prva =
	   		<div className = "row mar0 text-center" style = {{ padding: "4em 0 2em 0" }}>
				<div className = "col-xs-12 col-sm-4">
			        <div className = "col-xs-1"></div>
			        <div onClick = {()=>this.stran(1001)} className = "col-xs-10 album">
			            <div className = "row">
			                <img id = "ana" src = "slike/galerija/ana.jpg" className = "img-responsive" alt = ""/>
			            </div>
			            <div className = "row">
			                <h4> CERKEV SVETE ANE </h4>
			            </div>
			        </div>
			        <div className = "col-xs-1"></div>
			    </div>
			    <div className = "visible-xs col-xs-12" style = {{ marginBottom: "3em" }}></div>
			    <div className = "col-xs-12 col-sm-4">
			        <div className = "col-xs-1"></div>
			        <div onClick = { ()=>this.stran(1002) } className = "col-xs-10 album">
			            <div className = "row">
			                <img id = "tunjice" src = "/slike/galerija/tunjice.jpg" className = "img-responsive" alt = ""/>
			            </div>
			            <div className = "row">
			                <h4> ŽUPNIJA TUNJICE </h4>
			            </div>
			        </div>
			        <div className = "col-xs-1"></div>
			    </div>
			    <div className = "visible-xs col-xs-12" style = {{ marginBottom: "3em" }}></div>
			    <div className = "col-xs-12 col-sm-4">
			        <div className = "col-xs-1"></div>
			        <div onClick = { ()=>this.stran(1003) } className = "col-xs-10 album">
			            <div className = "row">
			                <img id = "dogodki" src = "/slike/galerija/dogodki.jpg" className = "img-responsive" alt = ""/>
			            </div>
			            <div className = "row">
			                <h4> DOGODKI </h4>
			            </div>
			        </div>
			        <div className = "col-xs-1"></div>
			    </div>
			</div>;

		let seznam_dogodkov = [
			["ROMANJE V MEDŽUGURJE 2018", "romanje2018", 1007],
			["IZLET CERKVENIH DELAVCEV 2018", "izlet2018", 1006],
			["SPREJEM NOVIH PEVCEV IN MINISTRANTOV 2017", "sprejem2017", 1005],
			["AVENTNE DELAVNICE 2015", "adventneDelavnice2015", 1004]		
		];

		let podstran = this.state.stran;

		if(window.history.state != null){
            podstran = window.history.state;
        }

		let stran = prva;
		let p = 0;

		if(podstran === 1001){

			stran = 
				<Album
					stran = {this.stran}
					naslov = "CERKEV SVETE ANE"
					nazaj_ime = "GALERIJA"
					nazaj = {1000}
					slike = {["ana", slike["ana"]]}
				/>;
		}
		else if(podstran === 1002){

			stran = 
				<Album
					avtor = {"Fotograf: Matjaž Sedušak"}
					stran = {this.stran}
					naslov = "ŽUPNIJA TUNJICE"
					nazaj_ime = "GALERIJA"
					nazaj = {1000}
					slike = {["tunjice", slike["tunjice"]]}
				/>;
		}
		else if(podstran === 1003){

			stran = 
				<Podgalerija 
					stran = {this.stran}
					naslov = "DOGODKI"
					nazaj_ime = "GALERIJA"
					seznam_dogodkov = {seznam_dogodkov}
					nazaj = {1000}
				/>;
		}
		else if(podstran === 1004){

			p = 1;

			stran = 
				<Album
					stran = {this.stran}
					opis = "Advetne delavnice so potekale v soboto, 28.11. 2015. Delavnice so se v Tunjicah začele ob 8.00, na Šenturški Gori pa ob 11.00. Naš dan smo začeli s telovadbo in jutranjo molitvijo, nato pa pričeli z delavnicami."
					naslov = {seznam_dogodkov[3][0]}
					nazaj_ime = "DOGODKI"
					nazaj = {1003}
					slike = {["dogodki/adventneDelavnice2015", slike["adventneDelavnice2015"]]}
				/>;
		}
		else if(podstran === 1005){

			p = 2;

			stran = 
				<Album
					avtor = {"Fotograf: Darja Mikuš"}
					stran = {this.stran}
					naslov = {seznam_dogodkov[p][0]}
					nazaj_ime = "DOGODKI"
					nazaj = {1003}
					slike = {["dogodki/sprejem2017", slike["sprejem2017"]]}
				/>;
		}
		else if(podstran === 1006){

			p = 1;

			stran = 
				<Album
					avtor = {"Fotograf: Darja Mikuš"}
					stran = {this.stran}
					naslov = {seznam_dogodkov[p][0]}
					nazaj_ime = "DOGODKI"
					nazaj = {1003}
					slike = {["dogodki/izlet2018", slike["izlet2018"]]}
				/>;
		}
		else if(podstran === 1007){

			p = 0;

			stran = 
				<Album
					avtor = {"Fotograf: David Rupnik"}
					stran = {this.stran}
					naslov = {seznam_dogodkov[p][0]}
					nazaj_ime = "DOGODKI"
					nazaj = {1003}
					slike = {["dogodki/romanje2018", slike["romanje2018"]]}
				/>;
		}

	  	return stran;
	}
}

export default Galerija;