import React from 'react';

import echo from './echo.js';

class Album extends React.Component{

	constructor(props){
		super(props);

		this.slika = this.slika.bind(this);

		this.state = {
			trenutni: -1
		}
	}

	componentDidMount(){
		echo();
	}

	nalozi(e){
		var c = e.currentTarget.getBoundingClientRect();
        
        if(c.top >= 0 && c.top <= (window.innerHeight || document.documentElement.clientHeight) ? !0 : !1){
			e.currentTarget.src = e.currentTarget.getAttribute("data-echo");
        }
	}

	slika(e, key, smer){

		let im = document.getElementById("lightbox");
		
		im.style.marginTop = (window.scrollY + "px");
		im.style.height = (window.innerHeight + "px");

		im.children[0].children[0].children[0].children[1].children[key].children[0].style.maxHeight = (window.innerHeight*0.8 + "px");

		let novi_trenutni = key;

		if(smer === -1){
			if(key === 0){
				novi_trenutni = this.props.slike[1].length - 1;
			}
			else{
				novi_trenutni = key - 1;
			}
		}
		else if(smer === 1){
			if(key === this.props.slike[1].length - 1){
				novi_trenutni = 0;
			}
			else{
				novi_trenutni = key + 1;
			}
		}

		this.setState({
			trenutni: novi_trenutni
		});

		let url = this.props.slike[1][novi_trenutni];

		if(this.refs[novi_trenutni].getAttribute("src") === "/slike/loading.gif"){

			let a = url.indexOf("avtor");
			let url_novi;

			if(a !== -1){
				let p = url.lastIndexOf(".");
				url_novi = url.substring(0, a - 1) + url.substring(p);
			}
			else{
				url_novi = url;
			}

			this.refs[novi_trenutni].setAttribute(
				"src",
				"/slike/galerija/" + this.props.slike[0] + "/velike/" + url_novi
			);
		}

		let z = url.indexOf("avtor");

		if(z !== -1){
			z += 6;

			let k = url.lastIndexOf(".");

			let temp_ime = url.substring(z, k);
			let ime_v_seznamu = temp_ime.split("-");

			let ime = "";

			for(let i = 0; i < ime_v_seznamu.length; i++){

				if(ime_v_seznamu[i].indexOf("Sedusak") !== -1){
					ime_v_seznamu[i] = "Sedušak";
				}
				else if(ime_v_seznamu[i].indexOf("Matjaz") !== -1){
					ime_v_seznamu[i] = "Matjaž";
				}
				else if(ime_v_seznamu[i].indexOf("Mikus") !== -1){
					ime_v_seznamu[i] = "Mikuš";
				}
				if(ime_v_seznamu[i].indexOf("Prelovsek") !== -1){
					ime_v_seznamu[i] = "Prelovšek";
				}

				ime += ime_v_seznamu[i] + " ";
			}

			this.refs.avtor.innerHTML = "Fotograf: " + ime;
		}
		else{
			this.refs.avtor.innerHTML = "";
		}
	}

	render(){

		let slike = [];
		let slike_modal = [];
		let slike_modal_li = [];

		let cl = " active";

		for(let i = 0; i < this.props.slike[1].length; i++){

			if(i === 1){
				cl = "";
			}

			slike_modal_li.push(
				<li onClick = {() => this.slika(null, i, 0)} className = {cl} key = {i} data-target = "#lightbox" data-slide-to = {i}></li>
			);

			slike_modal.push(
				<div key = {i} className = { "item" + cl}>
	        		<img src = "/slike/loading.gif" ref = {i} className = "center-block" style = {{ maxHeight: (window.innerHeight*0.8 + "px") }}  alt = ""/>
	        	</div>
	        );

			slike.push(
				<div
					className = "text-center col-xs-12 col-sm-6 col-md-4 col-lg-3 slika" 
					style = {{ height: "15em", padding: "1em" }}
					key = {i}
				>
					<img
						id = {"mala" + i}
						onClick = {() => this.slika(null, i, 0)}
						onLoad={this.nalozi}
						src = "/slike/loading.gif"
						data-slide-to = {i}
						data-target = "#lightbox" 
						data-toggle = "modal" 
						data-echo = { "/slike/galerija/" + this.props.slike[0] + "/male/" + this.props.slike[1][i] }
						className = "center-block img-responsive thumbnail"
						style = {{ maxHeight: "100%", cursor: "pointer" }}
						alt = ""
					/>
				</div>
			);
		}

		return(
			<div>
				<div id = "zacetek" className = "row mar0" style = {{ margin: "3em 2em 0 2em" }}>
					<div className = "col-xs-12 col-sm-4">
						<button onClick = {() => this.props.stran(this.props.nazaj)} className = "btn btn-default btn-lg"> 
			                &larr; {this.props.nazaj_ime}
			            </button>
			        </div>
			        <div className = "col-xs-12 col-sm-4 text-center">
			        	<h2 className = "naslovi">
			        		{this.props.naslov}
			        	</h2>
			        </div>
			        <div className = "col-xs-12 col-sm-4"></div>
		        </div>
		        <div className = "row mar0 text-center">
		        	<strong> {this.props.avtor} </strong>
		        </div>
		        {this.props.opis != null ? (
		        	<div className = "row mar0 text-center" style = {{ padding: "1em 0 0 0" }}>
		        		<div className = "col-xs-2"></div>
		        		<div className = "col-xs-8">
		        			{this.props.opis}
		        		</div>
		        		<div className = "col-xs-2"></div>
		        	</div>
		        ):(
		        	null
		        )}
		        <div className = "row mar0 text-center" style = {{ padding: "1em 2em 0 2em" }}>
		        	NASVET : Slike si lahko ogledate tudi v večji ločljivosti. To storite tako, da pritisnete na posamezno sliko, nato pa se premikate naprej ali nazaj s pomočjo smernih puščic.
		        </div>
		        <div className = "row mar0" style = {{ margin: "2em 2em 0 2em" }}>
					{slike}
				</div>
				<div className = "modal fade carousel slide" id = "lightbox" style = {{ display: "none", position: "absolute", height: (window.innerHeight + "px") }} aria-hidden = "true">
					<div className = "modal-dialog modal-lg">
					    <div className = "modal-content">        
					        <div className = "modal-body">
					            <ol className = "carousel-indicators">
					            	{slike_modal_li}
					            </ol>
					            <div className = "carousel-inner">
					            	{slike_modal}
					            	<a style={{ fontSize: "50px" }} onClick = {() => this.slika(null, this.state.trenutni, -1)} data-target = "#lightbox" href = "" className = "left carousel-control" role = "button" data-slide = "prev">
						                <strong><strong> &#x21d0; </strong></strong>
						            </a>
						            <a style={{ fontSize: "50px" }} onClick = {() => this.slika(null, this.state.trenutni, 1)} data-target = "#lightbox" href = "" className = "right carousel-control" role = "button" data-slide = "next">
						            	<strong><strong> &#x21d2; </strong></strong>
						            </a>
					            </div>
					        </div>
					        <div className = "modal-fotter text-center">		
					        	<h4 style = {{ padding: "0 0 0.7em 0" }} ref = "avtor"> </h4> 
					        </div>
					    </div>
					</div>
				</div>
			</div>
		);
	}
}

export default Album;