import React from "react";

import Domov from "./Domov.jsx";
import Zavetniki from "./Zavetniki.jsx";
import Urniki from "./Urniki.jsx";
import Krsti from "./Krsti.jsx";
import Poroke from "./Poroke.jsx";
import Oznanila from "./Oznanila.jsx";
import Galerija from "./Galerija.jsx";
import Ogledi from "./Ogledi.jsx";

let pretvorba = {
    "#domov": 0,
    "#zavetniki": 1,
    "#urniki": 2,
    "#oznanila": 3,
    "#krsti": 4,
    "#poroke": 5,
    "#galerija": 6,
    "#ogledi": 7
}

class App extends React.Component{

    constructor(props){
        super(props);

        this.osvezi = this.osvezi.bind(this);
        this.zgodovina = this.zgodovina.bind(this);

        this.state = {
            osvezi: 0
        };
    }

    componentDidMount(){

        if(window.history.state !== null){
            this.setState({
                osvezi: window.history.state
            });
        }
        else{
            window.history.replaceState(0, "", "#domov");
        }

        window.onpopstate = this.zgodovina;
    }

    zgodovina(e){
        if(window.history.state !== null){
            this.setState({
                osvezi: window.history.state
            });
        }
    }

    osvezi(i, url){
        
        // preprecitev ponovnega nalaganja iste podstrani
        if(i !== pretvorba[window.location.hash]){

            this.setState({
                osvezi: i
            });

            window.history.pushState(i, "", url);
        }
    }

    render(){

        let strani = [
            [<Domov/>, "", "#domov"],
            [<Zavetniki/>, "zavetniki | ", "#zavetniki"],
            [<Urniki/>, "urniki | ", "#urniki"],
            [<Oznanila/>, "oznanila in glasila | ", "#oznanila"],
            [<Krsti/>, "krsti | ", "#krsti"],
            [<Poroke/>, "poroke | ", "#poroke"],
            [<Galerija/>, "galerija | ", "#galerija"],
            [<Ogledi/>, "turistični ogledi | ", "#ogledi"]
        ];

        let st = this.state.osvezi;

        if(st >= 1000){
            st = 6;
        }

        let stran = strani[st][0];
        let naslov = strani[st][1] + "župnija Tunjice";

        document.title = naslov;

        return(
            <div>
                <div className = "row mar0 text-center glava-slika">
                    <div className = "col-xs-4"></div>
                    <div className = "col-xs-4">
                        <div className = "col-xs-4 col-sm-5"></div>
                        <div className = "col-xs-4 col-sm-2">
                            <img className = "img-responsive" src = "/slike/kriz.jpg" alt = "kriz" />
                        </div>
                        <div className = "col-xs-4 col-sm-5"></div>
                    </div>
                    <div className = "col-xs-4"></div>
                </div>
                <div className = "row mar0 text-center"> 
                    <h1 className = "glava-naslov naslovi">ŽUPNIJA TUNJICE</h1>
                </div>
                <div className = "row mar0 visible-xs visible-lg" style = {{ margin: "0 1rem" }}>
                    <ul className = "podnaslovi text-center nav nav-pills nav-justified">
                        <li><h2 onClick = {() => this.osvezi(0, strani[0][2])}  className = "roka"> DOMOV </h2></li>
                        <li><h2 onClick = {() => this.osvezi(1, strani[1][2])} className = "roka"> SVETA ANA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(2, strani[2][2])} className = "roka"> URNIK SVETIH MAŠ IN VEROUKA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(3, strani[3][2])} className = "roka"> OZNANILA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(4, strani[4][2])} className = "roka"> SVETI KRST </h2></li>
                        <li><h2 onClick = {() => this.osvezi(5, strani[5][2])} className = "roka"> SVETI ZAKON </h2></li>
                        <li><h2 onClick = {() => this.osvezi(1000, strani[6][2])} className = "roka"> GALERIJA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(7, strani[7][2])} className = "roka"> TURISTIČNI OGLED CERKVE </h2></li>
                    </ul>
                </div>
                <div className = "row mar0 visible-sm visible-md" style = {{ margin: "0 1rem" }}>
                    <ul className = "podnaslovi text-center nav nav-pills nav-justified">
                        <li><h2 onClick = {() => this.osvezi(0, strani[0][2])}  className = "roka"> DOMOV </h2></li>
                        <li><h2 onClick = {() => this.osvezi(1, strani[1][2])} className = "roka"> SVETA ANA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(2, strani[2][2])} className = "roka"> URNIK SVETIH MAŠ IN VEROUKA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(3, strani[3][2])} className = "roka"> OZNANILA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(4, strani[4][2])} className = "roka"> SVETI KRST </h2></li>
                    </ul>
                    <ul className = "podnaslovi text-center nav nav-pills nav-justified">
                        <li><h2 onClick = {() => this.osvezi(5, strani[5][2])} className = "roka"> SVETI ZAKON </h2></li>
                        <li><h2 onClick = {() => this.osvezi(1000, strani[6][2])} className = "roka"> GALERIJA </h2></li>
                        <li><h2 onClick = {() => this.osvezi(7, strani[7][2])} className = "roka"> TURISTIČNI OGLED CERKVE </h2></li>
                    </ul>
                </div>
                <div className = "row mar0">
                    <div className = "col-xs-1"></div>
                    <div className = "col-xs-10 crte"></div>
                    <div className = "col-xs-1"></div>
                </div>
                {stran}
                <div className = "row mar0 kontakt" id = "kontakt" style = {{ marginTop: "2em" }}>
                    <div className = "col-xs-12 col-sm-4 text-center">
                        <div className = "row">
                            <h3> KONTAKT </h3>
                        </div>
                        <div className = "row mar0" style = {{ paddingTop: "1em" }}>
                            <p> Župnija Kamnik </p>
                            <p> Raspov prehod 2 </p>
                            <p> 1241 Kamnik </p>
                            <p> zupnija.kamnik@rkc.si </p>
                            <p> (01) 831 92 90 </p>
                            <p> župnik: Luka Demšar </p>
                        </div>
                    </div>
                    <div className = "col-xs-12 col-sm-4 text-center" style = {{ padding: "0 5rem" }}>
                        <div className = "row">
                            <h3> URADNE URE V PISARNI </h3>
                        </div>
                        <div className = "row mar0" style = {{ paddingTop: "1em" }}>
                            <p> 
                                torek: 10.00 do 12.00
                            </p>
                            <p>
                                petek: 16.00 do 17.00
                            </p>
                        </div>
                    </div>
                    <div className = "col-xs-12 col-sm-4">
                        <div className = "row text-center mar0">
                            <h3> POVEZAVE </h3>
                        </div>
                        <div className = "row text-center mar0" style = {{ paddingTop: "0.5em" }}>
                            <a href = "http://www.rkc.si" style = {{ color: "rgb(172, 172, 177)" }}><h4> Katoliška Cerkev v Sloveniji </h4></a>
                            <a href = "http://franciskani-kamnik.rkc.si" style = {{ color: "rgb(172, 172, 177)" }}><h4> Frančiškanski samostan Kamnik </h4></a>
                            <a href = "http://www.tunjice.si" style = {{ color: "rgb(172, 172, 177)" }}><h4> Tunjice </h4></a>
                        </div>
                    </div>      
                </div>
                <div className = "row mar0 text-center"> Avtor: Samo Mikuš </div>
            </div>
        );
    }
}

export default App;