import React from 'react';

function Ogledi(){
   	
  	return(
  		<div>
  			<div className = "row mar0" style = {{ padding: "2em 0 2em 0" }}>
				<img src = "/slike/glave/oznanila.jpg" className = "img-responsive" alt = "" />
			</div>
			<div className = "row mar0 text-center" style = {{ paddingBottom: "1em" }}>
				<h2 className = "naslovi">
  					TURISTIČNI OGLED CERKVE
  				</h2>
  			</div>
  			<div className = "row mar0 text-center" style = {{ padding: "0 2em" }}>
				<p>
					Cerkev svete Ane Samotretje že več kot četrt tisočletja stoji na Bukovem griču v Tunjicah in fascinira njene obiskovalce. Pobudo za njeno gradnjo je dal nekdanji komendski župnik Peter Pavel Glavar, leta 1762 in jo dokončal v štirih letih. Čeprav gre za kulturni spomenik, cerkev še ni kategorizirana, tako da nima statusa niti kulturnega spomenika lokalnega pomena. Cerkev je tipično baročne arhitekture in je unikum baroka pri nas. 
				</p>
				<p>
					Spoštovani, v kolikor vas zanima turistični ogled našega svetišča ste k nam dobrodošli! Turistični ogledi so možni med delovniki in ob sobotah izven urnika župnijskih ali liturgičnih dejavnosti. Ob nedeljah in zapovedanih praznikih med tednom je cerkev izven terminov svetih maš zaprta. Vodeni ogled po cerkvi opravimo domači vodiči, medtem ko zunaj cerkve lahko vodenje opravijo tudi ostali. To velja tudi v primeru strokovnega ogleda. Ob vodenem ogledu cerkve se vam v naprej zahvaljujemo za dar, ki ga boste namenili za vzdrževanje kulturnega spomenika na Bukovem griču v Tunjicah. Tarifo turističnega ogleda je določil svet ključarjev župnije Tunjice.
  				</p>
  			</div>
  		</div>
	)
}

export default Ogledi;