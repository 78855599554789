import React from 'react';

function Poroke(){
   	
  	return(
  		<div>
  			<div className = "row" style = {{ padding: "2em 0" }}>
				<img src = "/slike/glave/krsti.jpg" className = "img-responsive" alt = "krsti" />
			</div>
			<div className = "row text-center" style = {{ padding: "0 6em" }}>
				<h2 className = "naslovi" style = {{ padding: "0 0 1em 0" }}> SVETI ZAKON </h2>
				<p>
					Vsaj tri mesece pred sklenitvijo zakonske zveze se ženin in nevesta oglasita osebno v času uradnih ur v župnijskem uradu na naslovu Raspov prehod 2, Kamnik. Namen tega srečanja je seznanitev s pogoji za veljavno sklenitev zakonske zveze, uskladitev časa in kraja poročne slovesnosti, določitev datuma sestavljanja poročnega zapisnika in pregled možnosti za opravljanje priprave na zakon.
				</p>
				<p>
					Pripravo na zakon je mogoče opravljati tudi v naši župniji. Ta poteka februarja in septembra. Organizirana je v obliki štirih zaporednih srečanj, in sicer dve soboti in dve nedelji. Več si lahko preberete v zgibanki <a style={{ color: "blue" }} target="_blank" rel="noopener noreferrer" href="/Priprava_na_zakon.pdf">Priprava na zakon</a>.
				</p>
			</div>
  		</div>
	)
}

export default Poroke;