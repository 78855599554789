export default function echo() {

    var j;
    var b = [];

    function render(){
        document.addEventListener ? document.addEventListener("DOMContentLoaded", g, !1) : window.onload = g;
    }

    function listen(){
        window.onscroll = g;
    }

    var je_viden = function(d){
        var c = d.getBoundingClientRect();
        return c.top >= 0 && c.top <= (window.innerHeight || document.documentElement.clientHeight) ? !0 : !1
    };

    var prikazi = function(d, j){
        d.src = d.getAttribute("data-echo");

        if(-1 !== b.indexOf(d.id)){
            b.splice(j, 1);
            j--;
        }
    };

    var g = function(){

        for (j = 0; j < b.length; j++) {
            var c = document.getElementById(b[j]);
            
            if(c != null && je_viden(c)){
                prikazi(c, j);
            }
        }
    };

    var p = document.querySelectorAll("img[data-echo], iframe[data-echo]");

    for (j = 0; j < p.length; j++){
        b.push(p[j].id);
    }

    render();
    listen();
}

