import React from 'react';

function Urniki(){
   	
  	return(
  		<div>
  			<div className = "row" style = {{ paddingTop: "2em" }}>
				<img src = "/slike/glave/urnik.jpg" className = "img-responsive" alt = "urnik" />
			</div>
			<div className = "row text-center" style = {{ paddingTop: "2em" }}>
				<h2 className = "naslovi" style = {{ paddingBottom: "0.5em" }}> URNIK SVETIH MAŠ </h2>				
				<p>
					petek: 18.00  (letni čas) oz. 17.00 (zimski čas) 
				</p>
				<p>
					nedelja: 9.00
				</p>
			</div>
			<div className = "row text-center" style = {{ paddingTop: "2em" }}>
				<h2 className = "naslovi" style = {{ paddingBottom: "0.5em" }}> URNIK VEROUKA </h2>
				<p>
					<strong><a href = "urnikveroukaTunjice.pdf"> urnik </a></strong>
				</p>
			</div>
  		</div>
	)
}

export default Urniki;