import React from 'react';

function Krsti(){
   	
  	return(
  		<div>
  			<div className = "row" style = {{ padding: "2em 0" }}>
				<img src = "slike/glave/krsti.jpg" className = "img-responsive" alt = "krsti" />
			</div>
			<div className = "row text-center" style = {{ padding: "0 6em" }}>
				<h2 className = "naslovi" style = {{ paddingBottom: "1em" }}> SVETI KRST </h2>
				<p>
					Krščevanje otrok je tretjo nedeljo v mesecu ob 10.30 (krstna nedelja). Pred tem se starši in botri krščenca prijavite na pripravo na krst, ki je v sredo pred tretjo nedeljo v mesecu po večerni maši v župnijskem uradu na naslovu Raspov prehod 2 v Kamniku. Starši s seboj prinesite rojstni list krščenca in poročeni starši tudi svojo družinsko knjižico. Botri, ki nimate stalnega ali začasnega prebivališča v Župniji Kamnik, s seboj prinesite potrdilo župnika svojega stalnega ali začasnega bivališča, da izpolnjujete pogoje za službo botra krščencu.
				</p>
				<p style= {{ padding: "1rem 0 0 0" }}>
					<h4> Pogosta vprašanja: </h4>
				</p>
				<p style= {{ padding: "1rem 0 0 0" }}>
					- Ali ima otrok lahko samo enega botra?
				</p>
				<p>
					Otrok ima lahko dva ali le enega botra. Če sta dva, je zaželjeno, da sta različnega spola.
				</p>
				<p style= {{ padding: "1rem 0 0 0" }}>
					- Ali potrebuje vsak boter potrdilo o sposobnosti za botra?
				</p>
				<p>
					Le botri, ki ne živijo v naši župniji. Potrdilo dobijo v župniji, kjer (trenutno) bivajo in se udeležujejo življenja v župniji. Domači botri se župniku predstavijo ob pripravi na krst.
				</p>
				<p style= {{ padding: "1rem 0 0 0" }}>
					- Koliko stane krst?
				</p>
				<p>
					Krst nima cene. Lahko pa ob krstu darujete prostovoljni dar za krščevalca.
				</p>
				<p style= {{ padding: "1rem 0 0 0" }}>
					- Ali je možen krst otroka iz druge župnije?
				</p>
				<p>
					Da, a je potrebno pisno dovoljenje župnika, kjer bivate.
				</p>
			</div>
  		</div>
	)
}

export default Krsti;