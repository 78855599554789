import React from 'react';



let oznanila = require.context('../public/oznanila', false, /\.(pdf)$/).keys();

oznanila.sort(function(a, b){
	let i = parseInt(a.substring(a.lastIndexOf("_") + 1, a.lastIndexOf(".")), 10);
	let j = parseInt(b.substring(b.lastIndexOf("_") + 1, b.lastIndexOf(".")), 10);

	return j - i;
});



function Oznanila(){

	let oznanila_html = [];
	let i = 0;

	for(let oznanilo of oznanila){

		let ime = oznanilo.split("_").join(" ");
		ime = ime.substring(2, ime.length - 6);

		oznanila_html.push(
			<div key = {i} className = "row text-center">
				<a target="_blank" rel="noopener noreferrer" href = { "/oznanila/" + oznanilo }> { ime } </a>
			</div>
		);

		i++;
	}

  	return(
  		<div>
  			<div className = "row mar0" style = {{ padding: "2em 0" }}>
				<img src = "/slike/glave/oznanila.jpg" className = "img-responsive" alt = "oznanila" />
			</div>
			<div className = "row mar0">
				<div className = "col-xs-1"></div>
				<div className = "col-xs-10">
					<div className = "row text-center" style = {{ paddingBottom: "10px" }}>
						<h2 className = "naslovi"> OZNANILA </h2>
					</div>
					{oznanila_html}
				</div>
				<div className = "col-xs-1"></div>
			</div>
  		</div>
	)
}

export default Oznanila;