import React from 'react';

function Zavetniki(){
   	
  	return(
  		<div>
  			<div className = "row" style = {{ padding: "2em 0" }}>
				<img src = "/slike/glave/ana.jpg" className = "img-responsive" alt = "sveta ana" />
			</div>
			<div className="row">
				<h3 className="text-center">
					SVETA ANA
				</h3>
				<div className = "row text-center" style = {{ paddingTop: "2em" }}> 
					<strong> ŽIVLJENJEPIS </strong> 
				</div> 
				<div className = "row text-center" style = {{ padding: "1em 4em 0 4em" }}>
					Sveta Ana je bila mati Device Marije. Ime Ana v hebrejščini pomeni milost, ljubezen, molitev. Kljub temu, da o sveti Ani iz Svetega pisma izvemo zelo malo, jo kristjani častimo z velikim zaupanjem, saj vemo, da jo je večni Bog izbral za mater Mariji, ki je bila mati našega Odrešenika. Sveta Ana je bila poročena z možem, ki mu je bilo ime Joahim. Dolga leta nista mogla imeti otrok. A na stara leta se jima je vendar uresničila želja, da bi imela lastnega otroka. Rodila se jima hči, Marija, ki sta jo starša imela pri sebi približno tri leta, nato pa jo izročila v varstvo v tempelj. Še kot nerodovitna sta se Joahim in Ana zaobljubila,da  če se jima bo vendar rodil otrok, da ga bosta izročila v varstvo v tempelj. Svojo obljubo sta izpolnila. Skozi zgodovino je sveta Ana  postala simbol materinstva, njen mož sveti Joahim pa simbol očetovstva. Češčenje sv. Ane se je začelo na vzhodu že v 6. stoletju po Kristusu in sicer na dan 25. julija, na zahodu pa v 8. stoletju in sicer na dan 26. julija. V Carigradu so že leta 550 postavili cerkev na čast sv. Ani. 
				</div>
				<div className = "row text-center" style = {{ padding: "2em 0 0 0" }}> 
					<strong> IKONOGRAFIJA </strong> 
				</div> 
				<div className = "row text-center" style = {{ padding: "1em 4em 1em 4em" }}>
					Sveto Ano upodabljajo kot mater, ki svoji hčeri razlaga sveto pismo. Največkrat jo umetniki upodabljajo kot sv. Ano Samotretjo, ko objema svojo hčer Marijo, v naročju pa drži svojega vnuka Jezusa. Sveta Ana Samotretja pomeni to, da kljub temu, da je sveta Ana zavetnica neke cerkve, ni ona tista, ki ji edini pripada naša čast. Prva oseba, ki ji pripada naša čast je Jezus, druga je Devica Marija, sama sveta Ana pa se ima šele za tretjo po vrsti.  
				</div>
				<div className = "row text-center" style = {{ padding: "1em 0" }}> 
					<strong> ZAVETNICA </strong> 
				</div> 
				<div className = "row text-center" style = {{ padding: "0 4em 0 4em" }}>
					Matere in nerodovitne žene so se že od nekdaj zatekale k sv. Ani, njej v čast so opravljale pobožnost devetih torkov po binkoštih, ker je torek posvečen sv. Ani. Ponekod po svetu se sv. Ani priporočajo za srečno poroko, za srečen zakon, za blagoslov otrok ali pa za srečen porod. Pri nas je sv. Ana bila priprošnjica za srečno zadnjo uro. Na Slovenskem je sv. Ani posvečenih več kot 70 župnijskih in podružničnih cerkva.  
				</div>
				<div className = "row text-center" style = {{ padding: "2em 4em 0 4em" }}>
					<em> Vir: dr. Maks Miklavčič-Jože Dolenc, Leto svetnikov III, Zadruga katoliških duhovnikov v Ljubljani 1972, str.: 213-215. </em>
				</div>
			</div>
  		</div>
	)
}

export default Zavetniki;