import React from 'react';

function Domov(){
   	
  	return(
  		<div>
  			<div className = "row mar0" style = {{ paddingTop: "2em" }}>
				<div id = "myCarousel" className = "carousel slide" data-ride = "carousel">
					<ol className = "carousel-indicators">
					    <li data-target = "#myCarousel" data-slide-to = "0" className = "active"></li>
					    <li data-target = "#myCarousel" data-slide-to = "1"></li>
					    <li data-target = "#myCarousel" data-slide-to = "2"></li>
					    <li data-target = "#myCarousel" data-slide-to = "3"></li>
					</ol>
					<div className = "carousel-inner" role = "listbox">
					    <div className = "item active">
					    	<img src = "/slike/prva/prva1.jpg" alt = "slika1" className = "img-responsive"/>
					    </div>
					    <div className = "item">
					    	<img src = "" data-src = "/slike/prva/prva2.jpg" alt = "slika2" className = "img-responsive"/>
					    </div>
					    <div className = "item">
					    	<img src = "" data-src = "/slike/prva/prva3.jpg" alt = "slika3" className = "img-responsive"/>
					    </div>
					    <div className = "item">
					    	<img src = "" data-src = "/slike/prva/prva4.jpg" alt = "slika4" className = "img-responsive"/>
					    </div>
					</div>
				</div>
			</div>
			<div className = "row text-center naslovi" style = {{ paddingTop: "2em" }}>
				<div className = "col-xs-2 col-md-1"></div>
				<div className = "col-xs-8 col-md-10">
					<p style =  {{ padding: "1em 0" }}>
						Župnija Tunjice je del Župnije Kamnik, ki spada v ljubljansko nadškofijo. Zavetnica tunjiške župnije je sveta Ana, mati Device Marije.
					</p>
				</div>
				<div className = "col-xs-2 col-md-1"></div>
			</div>
			<div className = "row text-center">
				<div className = "col-xs-1 col-md-3 col-lg-4"></div>
				<div className = "col-xs-10 col-md-6 col-lg-4">
					
				</div>
				<div className = "col-xs-1 col-md-3 col-lg-4"></div>
			</div>
  		</div>
	)
}

export default Domov;