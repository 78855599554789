import React from 'react';

import Album from './Album.jsx';

function Podgalerija(props){

	let dogodki = [];

	for(let i = 0; i < props.seznam_dogodkov.length; i++){
		dogodki.push(
			<div key = {i} className = "col-xs-12 col-sm-4 text-center" style = {{ padding: "2em" }}>
				<div className = "col-xs-1"></div>
				<div onClick = {() => props.stran(props.seznam_dogodkov[i][2])} className = "col-xs-10 pad0 album">
					<div className = "row mar0">
						<img id = {"dogodek" + i} src = {"/slike/galerija/" + props.seznam_dogodkov[i][1] + ".jpg"} className = "img-responsive" alt = "" />
					</div>
					<div className = "row mar0" style={{ padding: "0 2rem" }}>
						<h4> {props.seznam_dogodkov[i][0]} </h4>
					</div>
				</div>
				<div className = "col-xs-1"></div>
			</div>
		);
	}

	return(
		<div>
			<div className = "row mar0" style = {{ margin: "3em 2em 0 2em" }}>
				<div className = "col-xs-12 col-sm-4">
					<button onClick = {() => props.stran(props.nazaj)} className = "btn btn-default btn-lg"> 
		                &larr; {props.nazaj_ime}
		            </button>
		        </div>
		        <div className = "col-xs-12 col-sm-4 text-center">
		        	<h2 className = "naslovi">
		        		{props.naslov}
		        	</h2>
		        </div>
		        <div className = "col-xs-12 col-sm-4"></div>
	        </div>
	        <div className = "row mar0">
	        	{dogodki}
	        </div>
		</div>
	);
}

export default Podgalerija;